import { News } from '../../models/news'

export interface State {
  list: News[],
  isLoading: boolean
}

export const FETCH_LIST_REQUEST = 'app/news/FETCH_LIST_REQUEST'
export const FETCH_LIST_SUCCESS = 'app/news/FETCH_LIST_SUCCESS'
export const FETCH_LIST_FAILURE = 'app/news/FETCH_LIST_FAILURE'

interface FetchListRequestAction {
  type: typeof FETCH_LIST_REQUEST
}

interface FetchListSuccessAction {
  type: typeof FETCH_LIST_SUCCESS
  payload: { list: News[] }
}

interface FetchListFailureAction {
  type: typeof FETCH_LIST_FAILURE
}

export type FetchListActionTypes =
  | FetchListRequestAction
  | FetchListSuccessAction
  | FetchListFailureAction