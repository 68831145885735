import { Member } from '../../models/member'

export interface State {
  list: Member[],
  isListLoading: boolean
}

export const FETCH_LIST_REQUEST = 'app/member/FETCH_LIST_REQUEST'
export const FETCH_LIST_SUCCESS = 'app/member/FETCH_LIST_SUCCESS'
export const FETCH_LIST_FAILURE = 'app/member/FETCH_LIST_FAILURE'

interface FetchListRequestAction {
  type: typeof FETCH_LIST_REQUEST
}

interface FetchListSuccessAction {
  type: typeof FETCH_LIST_SUCCESS
  payload: { list: Member[] }
}

interface FetchListFailureAction {
  type: typeof FETCH_LIST_FAILURE
}

export type FetchListActionTypes =
  | FetchListRequestAction
  | FetchListSuccessAction
  | FetchListFailureAction
