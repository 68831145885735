import {
  State,
  FETCH_LIST_REQUEST,
  FETCH_LIST_SUCCESS,
  FETCH_LIST_FAILURE,
  FetchListActionTypes
} from './types'

export const initialState: State = {
  list: [],
  isLoading: false
}

export function reducer(
  state = initialState,
  action: FetchListActionTypes
): State {
  switch (action.type) {
    case FETCH_LIST_REQUEST:
      return { ...state, isLoading: true }

    case FETCH_LIST_SUCCESS:
      const newNewsList = []
      action.payload.list.forEach(newNews => {
        if (!state.list.find(news => news.id === newNews.id)) {
          newNewsList.push(newNews)
        }
      })

      return {
        ...state,
        list: state.list.concat(newNewsList),
        isLoading: false
      }

    case FETCH_LIST_FAILURE:
      return { ...state, isLoading: false }
    
    default:
      return state
  }
}
