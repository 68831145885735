import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import resources from './locales'


i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'ja',
  debug: false,

  interpolation: {
    escapeValue: false
  }
})

export { i18n }