import {
  State,
  FETCH_COMPANY_REQUEST,
  FETCH_COMPANY_SUCCESS,
  FETCH_COMPANY_FAILURE,
  FetchCompanyActionTypes
} from './types'

export const initialState: State = {
  company: null
}

export function reducer(
  state = initialState,
  action: FetchCompanyActionTypes
): State {
  switch (action.type) {
    case FETCH_COMPANY_REQUEST:
      return { ...state }

    case FETCH_COMPANY_SUCCESS:
      return {
        ...state,
        company: action.payload.company
      }

    case FETCH_COMPANY_FAILURE:
      return { ...state }

    default:
      return state
  }
}
