import { Science } from '../../models/science'

export interface State {
  list: Science[],
  isListLoading: boolean
}

export const FETCH_LIST_REQUEST = 'app/science/FETCH_LIST_REQUEST'
export const FETCH_LIST_SUCCESS = 'app/science/FETCH_LIST_SUCCESS'
export const FETCH_LIST_FAILURE = 'app/science/FETCH_LIST_FAILURE'

interface FetchListRequestAction {
  type: typeof FETCH_LIST_REQUEST
}

interface FetchListSuccessAction {
  type: typeof FETCH_LIST_SUCCESS
  payload: { list: Science[] }
}

interface FetchListFailureAction {
  type: typeof FETCH_LIST_FAILURE
}

export type FetchListActionTypes =
  | FetchListRequestAction
  | FetchListSuccessAction
  | FetchListFailureAction
