import {
  State,
  FETCH_LIST_REQUEST,
  FETCH_LIST_SUCCESS,
  FETCH_LIST_FAILURE,
  FetchListActionTypes
} from './types'

export const initialState: State = {
  list: [],
  isListLoading: false
}

export function reducer(
  state = initialState,
  action: FetchListActionTypes
): State {
  switch (action.type) {
    case FETCH_LIST_REQUEST:
      return { ...state, isListLoading: true }

    case FETCH_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload.list,
        isListLoading: false
      }

    case FETCH_LIST_FAILURE:
      return { ...state, isListLoading: false }

    default:
      return state
  }
}
