import { Company } from '../../models/company'

export interface State {
  company: Company | null
}

export const FETCH_COMPANY_REQUEST = 'app/company/FETCH_COMPANY_REQUEST'
export const FETCH_COMPANY_SUCCESS = 'app/company/FETCH_COMPANY_SUCCESS'
export const FETCH_COMPANY_FAILURE = 'app/company/FETCH_COMPANY_FAILURE'

interface FetchCompanyRequestAction {
  type: typeof FETCH_COMPANY_REQUEST
}

interface FetchCompanySuccessAction {
  type: typeof FETCH_COMPANY_SUCCESS
  payload: { company: Company }
}

interface FetchCompanyFailureAction {
  type: typeof FETCH_COMPANY_FAILURE
}

export type FetchCompanyActionTypes =
  | FetchCompanyRequestAction
  | FetchCompanySuccessAction
  | FetchCompanyFailureAction
