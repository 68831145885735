import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk'

import { reducer as newsReducer } from './news/reducers'
import { reducer as memberReducer } from './member/reducers'
import { reducer as companyReducer } from './company/reducers'
import { reducer as historyReducer } from './history/reducers'
import { reducer as scienceReducer } from './science/reducers'

export const rootReducer = combineReducers({
  news: newsReducer,
  member: memberReducer,
  company: companyReducer,
  history: historyReducer,
  science: scienceReducer
})

export default () => {
  return createStore(rootReducer, applyMiddleware(thunk))
}
